// in src/authProvider.js
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from "react-admin";

const authProvider = (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    let serverURL = "https://manage.bassettbarks.com.au/api/auth/local";
    if (process.env.NODE_ENV === "development") {
      serverURL = "http://localhost:1337/api/auth/local";
    }
    const request = new Request(serverURL, {
      method: "POST",
      body: JSON.stringify({ identifier: username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    });
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        localStorage.setItem("token", json.jwt);
      });
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem("token");
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  }
  return Promise.resolve();
};

export default authProvider;
