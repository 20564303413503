import React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Filter,
  ReferenceInput,
  BooleanInput,
  AutocompleteInput,
  ReferenceField,
  useGetOne,
  WrapperField,
  useRecordContext,
} from "react-admin";

const RequiredworkRowStyle = (record, index) => {
  // Check on the status of the required work

  if (record.lastReading !== null) {
    /* let machineElapsed = machine.currentReading - record.lastReading;
    let remaining = worktype.interval - machineElapsed;
    // Have we got more time?
    if (remaining > 0) {
      if (remaining >= worktype.threshold) {
        return {};
      } else {
        return { backgroundColor: "yellow" };
      }
    } */
    return {};
  }
  // This work has never been performed, red alert
  return { backgroundColor: "red" };
};

const RequiredworkFilter = (props) => (
  <Filter {...props}>
    <BooleanInput source="due" label="Due services" />
    <ReferenceInput
      label="Machine"
      source="machine"
      reference="machines"
      filterToQuery={(searchText) => ({ name_contains: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const RemainingTillDue = (props) => {
  const record = useRecordContext();
  const {
    data: machine,
    isLoading: isMachineLoading,
    error: machineError,
  } = useGetOne("machines", { id: record.machine });
  const {
    data: worktype,
    isLoading: isWorktypeLoading,
    error: worktypeError,
  } = useGetOne("worktypes", { id: record.worktype });
  if (isMachineLoading || machineError || isWorktypeLoading || worktypeError) {
    return null;
  }
  if (record.lastReading) {
    let machineElapsed = machine.currentReading - record.lastReading;
    let remaining = worktype.interval - machineElapsed;
    if (remaining > 0) {
      if (!record.due) return `${remaining} ${machine.readingType}`;
      return (
        <div
          style={{
            color: "black",
            backgroundColor: "yellow",
            textAlign: "center",
          }}
        >{`${remaining} ${machine.readingType}`}</div>
      );
    }
    return (
      <div
        style={{
          color: "white",
          backgroundColor: "red",
          textAlign: "center",
        }}
      >{`${remaining} ${machine.readingType}`}</div>
    );
  }
  return (
    <div
      style={{
        color: "white",
        backgroundColor: "red",
        textAlign: "center",
      }}
    >
      Unknown
    </div>
  );
};

export const RequiredworkList = (props) => (
  <List
    {...props}
    title="Services"
    filters={<RequiredworkFilter />}
    filterDefaultValues={{ due: true }}
    perPage={50}
  >
    <Datagrid bulkActionButtons={false} rowClick={false}>
      <ReferenceField
        source="worktype"
        sortBy="worktype.name"
        label="Service type"
        reference="worktypes"
        link={false}
      />
      <ReferenceField
        source="machine"
        sortBy="machine.name"
        label="Machine"
        reference="machines"
        link={false}
      />
      <ReferenceField
        label="Current Reading"
        reference="machines"
        source="machine"
        link={false}
        sortable={false}
      >
        <TextField source="currentReading" /> <TextField source="readingType" />
      </ReferenceField>
      <WrapperField label="Last Completed">
        <FunctionField
          render={(record) => {
            return record.lastReading || "?";
          }}
        />{" "}
        <ReferenceField
          reference="machines"
          source="machine"
          link={false}
          sortable={false}
        >
          <TextField source="readingType" />
        </ReferenceField>
      </WrapperField>
      <WrapperField label="Remaining till due" sortable={false}>
        <RemainingTillDue />
      </WrapperField>

      {/* 
      <FunctionField
        label="Interval"
        render={(record) =>
          `${record.worktype.data.attributes.interval} ${record.machine.data.attributes.readingType}`
        }
      />
      <FunctionField
        label="Threshold"
        render={(record) =>
          `${record.worktype.data.attributes.threshold} ${record.machine.data.attributes.readingType}`
        }
      />
       */}
      <ReferenceField
        source="worktype"
        reference="worktypes"
        label="Worksheet"
        sortable={false}
      >
        <FunctionField
          render={(record) => {
            if (record.sheetUrl && record.sheetUrl.length > 0) {
              return (
                <a href={record.sheetUrl} target="_blank" rel="noreferrer">
                  Open
                </a>
              );
            }
          }}
        />
      </ReferenceField>
    </Datagrid>
  </List>
);
