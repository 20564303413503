import { Admin, Resource, fetchUtils } from "react-admin";
import raStrapiRest from "./ra-strapi-rest";

import { MachineEdit, MachineList, MachineShow } from "./resources/machines.js";
import { RequiredworkList } from "./resources/requiredWork.js";
import { WorkList, WorkEdit, WorkCreate } from "./resources/work.js";

import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import WorkIcon from "@mui/icons-material/Work";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";

import authProvider from "./authProvider.js";

const httpClient = (url: string, options: any = {}) => {
  options.headers =
    options.headers || new Headers({ Accept: "application/json" });
  const token = localStorage.getItem("token");
  options.headers.set("Authorization", `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

let dataProvider = raStrapiRest(
  "https://manage.bassettbarks.com.au/api",
  httpClient
);

if (process.env.NODE_ENV === "development") {
  dataProvider = raStrapiRest("http://localhost:1337/api", httpClient);
}

const App = () => (
  <Admin dataProvider={dataProvider} authProvider={authProvider}>
    <Resource
      name="machines"
      list={MachineList}
      show={MachineShow}
      edit={MachineEdit}
      icon={LocalShippingIcon}
      recordRepresentation={(record) => record.name}
    />
    <Resource
      name="machinetypes"
      recordRepresentation={(record) => record.name}
    />
    <Resource
      name="works"
      options={{ label: "Logbook" }}
      list={WorkList}
      edit={WorkEdit}
      create={WorkCreate}
      icon={WorkIcon}
    />
    <Resource name="worktypes" recordRepresentation={(record) => record.name} />
    <Resource name="users" recordRepresentation={(record) => record.fullName} />
    <Resource
      name="requiredworks"
      options={{ label: "Services" }}
      list={RequiredworkList}
      icon={AssignmentTurnedInIcon}
    />
  </Admin>
);

export default App;
