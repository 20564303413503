import React from "react";
import {
  List,
  DateField,
  AutocompleteInput,
  Filter,
  Datagrid,
  TextField,
  FunctionField,
  RichTextField,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  EditButton,
  ShowButton,
  BooleanField,
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  BooleanInput,
  SaveButton,
  Toolbar,
  ReferenceField,
  useRecordContext,
  useGetOne,
} from "react-admin";

const MachineFilter = (props) => (
  <Filter {...props}>
    <BooleanInput source="active" />
    <ReferenceInput
      label="Machine Type"
      source="machinetype"
      reference="machinetypes"
      filterToQuery={(searchText) => ({ name_contains: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

export const MachineList = (props) => (
  <List
    {...props}
    filterDefaultValues={{ active: true }}
    filters={<MachineFilter />}
  >
    <Datagrid
      bulkActionButtons={false}
      rowClick="expand"
      expand={<MachineExpand />}
    >
      <ReferenceField
        source="machinetype"
        reference="machinetypes"
        sortBy="machinetype.name"
        label="Type"
      />

      <TextField source="name" />
      <TextField source="description" />
      <FunctionField
        label="Current Reading"
        source="currentReading"
        render={(record) => `${record.currentReading} ${record.readingType}`}
      />
      <EditButton />
      <ShowButton />
    </Datagrid>
  </List>
);

const MachineTitle = () => {
  const record = useRecordContext();
  const {
    data: machinetype,
    isLoading,
    error,
  } = useGetOne("machinetypes", { id: record.machinetype });
  if (isLoading || error) {
    return null;
  }
  return <span>{machinetype.name + ": " + record.name}</span>;
};

export const MachineShow = (props) => (
  <Show {...props} title={<MachineTitle />}>
    <SimpleShowLayout>
      <BooleanField source="active" />
      <TextField source="description" />
      <TextField source="notes" />
      <ReferenceManyField
        label="Required Services"
        reference="requiredworks"
        target="machine_id"
        sort={{ field: "worktype.name", order: "DESC" }}
      >
        <Datagrid bulkActionButtons={false}>
          <ReferenceField
            source="worktype"
            reference="worktypes"
            label="Serice type"
          />
          <ReferenceField
            source="worktype"
            reference="worktypes"
            label="Interval"
            sortable={false}
          >
            <TextField source="interval" />
          </ReferenceField>
          <ReferenceField
            source="worktype"
            reference="worktypes"
            label="Threshold"
            sortable={false}
          >
            <TextField source="threshold" />
          </ReferenceField>
          <ReferenceField
            source="worktype"
            reference="worktypes"
            label="Service sheet"
            sortable={false}
          >
            <FunctionField
              label="Service Sheet"
              render={(record) => {
                if (record.sheetUrl && record.sheetUrl.length > 0) {
                  return (
                    <a href={record.sheetUrl} target="_blank" rel="noreferrer">
                      Open
                    </a>
                  );
                }
              }}
            />
          </ReferenceField>
        </Datagrid>
      </ReferenceManyField>
      <ReferenceManyField
        label="Recent Work"
        reference="works"
        target="machine_id"
        sort={{ field: "date", order: "DESC" }}
        perPage={10}
      >
        <Datagrid bulkActionButtons={false}>
          <DateField source="date" />
          <ReferenceField
            source="worktype"
            reference="worktypes"
            label="Work type"
          />
          <TextField source="reading" sortable={false} />
          <RichTextField source="details" sortable={false} />
        </Datagrid>
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
);

const MachineEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

export const MachineExpand = (props) => (
  <Edit {...props} title=" ">
    <SimpleForm toolbar={<MachineEditToolbar />}>
      <TextInput source="currentReading" />
    </SimpleForm>
  </Edit>
);

export const MachineEdit = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<MachineEditToolbar />}>
      <TextInput disabled={true} source="id" />
      <BooleanInput source="active" />
      <ReferenceInput
        label="Machine Type"
        source="machinetype"
        reference="machinetypes"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="name" />
      <NumberInput source="currentReading" />
      <TextInput source="description" />
      <TextInput source="notes" />
      <SelectInput
        source="readingType"
        choices={[
          { id: "Hrs", name: "Hours" },
          { id: "Kms", name: "Kilometers" },
        ]}
      />
    </SimpleForm>
  </Edit>
);
