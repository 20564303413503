import React from "react";
import {
  List,
  DateField,
  Datagrid,
  downloadCSV,
  TextField,
  AutocompleteInput,
  Filter,
  Show,
  SimpleShowLayout,
  Create,
  RichTextField,
  EditButton,
  Edit,
  SimpleForm,
  TextInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ReferenceField,
  WrapperField,
} from "react-admin";
import { RichTextInput } from "ra-input-rich-text";
import jsonExport from "jsonexport/dist";

const WorkShow = (props) => (
  <Show
    {...props}
    /* disable the app title change when shown */
    title=" "
    actions={false}
  >
    <SimpleShowLayout>
      <DateField source="date" label="Completed" />
      <DateField source="createdAt" label="Entered" />
      <ReferenceField source="user" label="Completed by" reference="users" />
      <RichTextField source="details" />
    </SimpleShowLayout>
  </Show>
);

const WorkFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput
      label="Work Type"
      source="worktype"
      reference="worktypes"
      filterToQuery={(searchText) => ({ name_contains: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      label="Machine"
      source="machine"
      reference="machines"
      filterToQuery={(searchText) => ({ name_contains: searchText })}
    >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
  </Filter>
);

const exporter = (work) => {
  const workForExport = work.map((entry) => {
    const { id, created_at, updated_at, ...fields } = entry;
    fields.date = new Date(fields.date).toDateString();
    return fields;
  });
  jsonExport(
    workForExport,
    {
      headers: ["date", "machine", "reading", "worktype", "details"],
    },
    (err, csv) => {
      downloadCSV(csv, "logbook-entries");
    }
  );
};

export const WorkList = (props) => (
  <List
    {...props}
    title="Logbook"
    filters={<WorkFilter />}
    exporter={exporter}
    sort={{ field: "date", order: "DESC" }}
  >
    <Datagrid bulkActionButtons={false} rowClick="expand" expand={<WorkShow />}>
      <DateField source="date" label="Completed" locales="en-GB" />
      <ReferenceField
        source="user"
        label="Completed by"
        sortBy="user.name"
        reference="users"
        link={false}
      />
      <ReferenceField
        source="worktype"
        reference="worktypes"
        label="Work Type"
        sortBy="worktype.name"
        link={false}
      />
      <ReferenceField
        source="machine"
        label="Machine"
        sortBy="machine.name"
        reference="machines"
        link={false}
      />
      <WrapperField label="Reading">
        <TextField source="reading" />{" "}
        <ReferenceField source="machine" reference="machines" link={false}>
          <TextField source="readingType" />
        </ReferenceField>
      </WrapperField>
      <EditButton />
    </Datagrid>
  </List>
);

const validateWork = (values) => {
  const errors = {};
  if (!values.date) {
    errors.date = "Date is required.";
  }
  if (!values.worktype) {
    errors.worktype = "Work type is required.";
  }
  if (!values.machine) {
    errors.machine = "Machine is required.";
  }
  if (!values.reading) {
    errors.reading = "Reading is required.";
  }
  return errors;
};

export const WorkEdit = (props) => (
  <Edit {...props} title="Edit Logbook Entry">
    <SimpleForm validate={validateWork}>
      <TextInput source="id" disabled={true} />
      <DateInput source="date" label="Completed" />
      <ReferenceInput source="worktype" reference="worktypes">
        <AutocompleteInput source="name" label="Work type" fullWidth />
      </ReferenceInput>
      <ReferenceInput
        source="machine"
        reference="machines"
        filter={{ active: true }}
      >
        <AutocompleteInput source="name" label="Machine" fullWidth />
      </ReferenceInput>
      <ReferenceInput source="user" reference="users">
        <SelectInput optionText="fullName" label="Completed By" />
      </ReferenceInput>

      <NumberInput source="reading" />
      <RichTextInput source="details" />
    </SimpleForm>
  </Edit>
);

export const WorkCreate = (props) => (
  <Create
    {...props}
    title="Create Logbook Entry"
    transform={(values) => {
      return { data: values };
    }}
    redirect="list"
  >
    <SimpleForm validate={validateWork}>
      <DateInput source="date" label="Completed" />
      <ReferenceInput source="worktype" reference="worktypes">
        <AutocompleteInput source="name" label="Work type" fullWidth />
      </ReferenceInput>
      <ReferenceInput
        source="machine"
        reference="machines"
        filter={{ active: true }}
      >
        <AutocompleteInput source="name" label="Machine" fullWidth />
      </ReferenceInput>
      <ReferenceInput source="user" reference="users">
        <SelectInput optionText="fullName" label="Completed By" />
      </ReferenceInput>
      <NumberInput source="reading" />
      <RichTextInput source="details" />
    </SimpleForm>
  </Create>
);
